<template>
	<div class="outer-wrapper">

		<div class="inner-wrapper">
			
			<div class="heading-section">
				<h1 class="heading4">{{heading.title}}</h1>
				<span class="caption">{{heading.sub_title}}</span>
			</div>

			<!-- Temporarily disabled until we have better databases for destinations on this page. -->
			<SearchSlideout class="bar"/>

			<div class="add-more">
				<div class="add-more-container">
					<router-link class="item-wrapper has-text" :to="{ name: 'Flights Locations' }">
						<div class="item">
							<img src="@/assets/trip/flight-large.png" alt="Flight Icon" width="36px"/>
						</div>
						<div class="item-text">
							Flights
						</div>
					</router-link>
					<router-link class="item-wrapper has-text" :to="{ name: 'Tours Keyword' }">
						<div class="item">
							<img src="@/assets/trip/tour-large.png" alt="Tour Icon" width="36px"/>
						</div>
						<div class="item-text">
							Tours
						</div>
					</router-link>
				</div>
			</div>

		</div>

		<div class="inner-wrapper">
			<h2 class="heading7">Our Mission</h2>
		</div>
		<router-link :to="{ name: 'Mission' }" style="text-decoration: none">
			<div class="main-content" :style="{'background-image': 'url(' + getImage('mission/mission-image') + ')'}">
				<h3 class="title">{{mission.title}}</h3>
				<!-- <button class="button">Read More</button> -->
			</div>
		</router-link>

		<div class="country-wrapper" >
			<div class="inner-wrapper">
				<h2 class="heading7">Countries</h2>
			</div>

			<Loader :loading="countriesLoading" :error="error" @refresh="initTours()"/>

			<swiper ref="countrySwiper" class="slider countries" 
				v-if="!countriesLoading"  
				:options="swiperOptions"
			>
				<div class="prev" slot="button-prev"><img src="@/assets/arrows/circle-arrow.png" width="25px"/></div>
				<div class="next" slot="button-next"><img src="@/assets/arrows/circle-arrow.png" width="25px"/></div>
				<swiper-slide class="slide" v-for="country in countries" :key="country">
					<router-link :to="country.url" class="country-card" :style="{'background-image': 'url(' + country.fields.header_image + ')'}">
						<!-- <div class="name">{{ country.meta.page_title }}</div> -->
						<div class="name">{{ country.fields.heading }}</div>
					</router-link>
				</swiper-slide>
			</swiper>
		</div>

		<div class="tour-wrapper" >
			<div class="inner-wrapper">
				<h2 class="heading7">Tours</h2>
			</div>

			<Loader :loading="toursLoading" :error="error" @refresh="initTours()"/>

			<swiper ref="tourSwiper" class="slider tours" 
				v-if="!toursLoading"  
				:options="swiperOptions"
			>
				<div class="prev" slot="button-prev"><img src="@/assets/arrows/circle-arrow.png" width="25px"/></div>
				<div class="next" slot="button-next"><img src="@/assets/arrows/circle-arrow.png" width="25px"/></div>
				<swiper-slide class="slide" v-for="tour in tours" :key="tour.id">
					<TourTallCard
						class="tour-card"

						:id="tour.id"
						:name="tour.name"
						:images="tour.images"
						:startLocation="tour.start_location"
						:endLocation="tour.end_location"
						:length="tour.length"
						:description="tour.description"
						:fromPrice="tour.advertised_price ? tour.advertised_price.find(obj => { return obj.currency == 'GBP' }) : false"
					/>
				</swiper-slide>
			</swiper>
		</div>

	</div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import SearchSlideout from '@/components/SearchSlideout.vue'
import { mapState, mapActions } from 'vuex';
import helpers from '@/helpers/helpers.js';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { searchService } from '@/services/searchService.js'
import contentService from '@/services/contentService.js'
import TourTallCard from "@/components/tours/TourTallCard.vue";

export default {
	name: 'Home',
	data() {
		return {
			sliders: {
				big_slider_title: "Trending Destinations",
				small_slider_title: "Community Stories",
			},
			mission: {
				sub_title: "Our Mission",
				title: "To make doing good easy."
			},
			heading: {
				title: "Sustainable travel",
				// sub_title: "Travel with Purpose and have an impact."
			},
			toursLoading: false,
			countriesLoading: false,

			countryCodes: [], // array of country codes fetched from the CMS, to be used to fetch tours

			error: false,

			tours: [],
			countries: ["Thailand", "Indonesia", "Vietnam", "Cambodia", "Laos", "Malaysia", "Myanmar", "Philippines", "Singapore", "Brunei", "East Timor"],

			swiperOptions: {
				slidesPerView: "auto",
				navigation: {
					nextEl: '.next',
					prevEl: '.prev',
				},
				loop: "true",
				grabCursor: "true",
				centeredSlides: "true",
				spaceBetween: 10,
				coverflowEffect: {
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				},
			},
		}
	},
	components: {
		Loader,
		SearchSlideout,
		Swiper,
		SwiperSlide,
		TourTallCard,
	},
	directives: {
		swiper: directive
	},
	computed: {
		...mapState({
			alert: state => state.alert,
			blogs: state => state.blogs.blogs
		}),
		// blogCardsData() {
		// 	var blogCards = [];
		// 	for(let index in this.blogs) {
		// 		let ele = this.blogs[index];
		// 		blogCards.push({
		// 			id: ele.id,
		// 			title: ele.fields.heading,
		// 			img: ele.fields.featured_image,
		// 		});
		// 	}
			
		// 	return blogCards;
		// }
	},
	methods: {
		...mapActions({
			alertError: "alert/error",
			fetchBlogs: "blogs/fetchBlogs"
		}),
		// fetchPosts: function() {
		// 	if(this.blogs.length == 0) {
		// 		this.toursLoading = true;
		// 	}
		// 	this.fetchBlogs()
		// 		.then((response) => {
		// 			this.toursLoading = false;
		// 			this.error = false;
		// 		},
		// 		error => {
		// 			this.alertError("Failed to load...");
		// 			this.error = "Failed to load...";
		// 			this.toursLoading = false;
		// 		});
		// },
		getImage: (img) => helpers.getImage(img),
		async initCountries() {
			this.countriesLoading = true;
			return contentService.getAllCountries()
				.then(response => {
					if(response) {
						this.countries = Object.values(response.data.data) // Convert to array
					}
					//Loop through each response.data.data and get the country code
					this.countryCodes = this.countries.map((country) => {
						// Return the second part of the url, which is the country code
						return country.url.split("/")[2];
					});	

					this.error = null;
					this.countriesLoading = false;
				},
				error => {
					this.error = error;
					this.countriesLoading = false;
				});
		},
		initTours() {
			this.toursLoading = true;

			// Loop through this.countryCodes and fire the endpoints, stored in array of promises, for each
			Promise.all(this.countryCodes.map((countryCode) => {
				return searchService.getCountryTours(countryCode)
				.catch((error) => {
					// console.log(`Error with country code ${countryCode}: ${error}`);
					return null;  // Return null so that a failure for one request will still let .all() resolve and handle the successful requests
				});
			}))
			.then((responses) => { // Once all promises are resolved, loop through the responses and add the tours to this.tours
				responses.forEach((response) => {
					if(response && response.data && response.data.data) {  // check if response is not null or your error object
						// Add first 10 tours for each country
						this.tours = this.tours.concat(Object.values(response.data.data).slice(0, 10)); // Convert to array
						// Randomize the order
						this.tours = this.tours.sort(() => Math.random() - 0.5);
					}
				});
				this.error = null;
				this.toursLoading = false;
			})
		}
	},
	async created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'home',
			},
			right: {
				type: 'menu',
			},
		});
		this.countriesLoading = true;
		this.toursLoading = true;
		// this.fetchPosts();
		await this.initCountries(); // Await, as we will use the countries to determine which tours to get
		this.initTours();
	}
}
</script>

<style scoped>
	.heading4 {
		font-size: 29px;
	}
	.add-more {
		text-align: center;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.main-content {
		max-width: 100%;
		height: 250px;
		text-align: center;
		margin: 0 auto;
		background-size: cover;
		margin-bottom: 15px;
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
	}
	.main-content .title {
		color: #FFFFFF;
		margin: auto;
		vertical-align: middle;
		padding: 10px 25px;
		font-weight: 700;
		font-size: 34px;
		line-height: 41px;
	}

	.heading7 {
		color: black;
		margin-bottom: 5px;
		margin-bottom: 20px;
	}
	.bar {
		margin-top: 20px;
	}
	
	.heading {
		text-align: center;
		width: 240px;
		margin: auto;
		padding-bottom: 30px;
		padding-top: 36px;
	}
	@media (min-width: 960px) { /* Large Tablets + Desktop */
		.heading {
			width: 500px;
		}
		.heading h3 {
			margin-bottom: 20px;
		}
		.category-links {
			padding-top: 35px;
		}
	}
	.country-wrapper,
	.tour-wrapper {
		margin-bottom: 30px;
	}
	/* both sliders */
	.slider .next,
	.slider .prev {
		position: absolute;
		z-index: 1;
		cursor: pointer;
		top: 50%;
		width: 25px;
		height: 25px;
	}
	.slider .next {
		right: 10px;
	}
	.slider .prev {
		left: 10px;
	}
	.slider .prev img {
		transform: rotate(180deg);
	}

	/* Tours slider */
	.tours {
		margin-top: 20px;
		margin-bottom: 60px;
	}
	.tours .slide {
		width: 268px;
	}
	

	/* Countries slider */
	.countries {
		margin-top: 20px;
		margin-bottom: 30px;
	}
	.countries .slide {
		width: 240px;
	}
	.country-card {
		display: block;
		background: #E5E5E5;
		box-sizing: border-box;
		position: relative;
		width: 240px;
		height: 240px;
		border-radius: 10px;
		padding: 20px;

		background-repeat: no-repeat;
		background-size: cover;
	}
	.country-card .name {
		position: absolute;
		bottom: 0;
		color: white;
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		padding-bottom: inherit;
		padding-right: inherit;
	}
</style>
