<template>
	<div class="searchInputWrapper">
		<v-select 
			ref="searchInput"
			class="search-input" 
			v-model="location" 
			:options="options" 
			label="name" 
			@search="searchLocations"
			:placeholder="placeholder" 
			:filterable="false"
			@input="handleSelect"
			:components="{OpenIndicator, Deselect}"
		> 
			<template v-slot:no-options="{ search, searching }">
				<template v-if="searching">
					<div class="info">
						<div v-if="error != null">{{ error }}</div>
						<div v-else>No results found for <em>{{ search }}</em>.</div>
					</div>
				</template>
				<template v-if="!searching">
					<div class="hide">
						<div>Type to search</div>
					</div>
				</template>
			</template>
			
			<template slot="option" slot-scope="option">
				<div class="option">
					<!-- Display the display property, or the name property if display is not available -->
					<div class="option-name" v-if="option.display" v-html="highlight(option.display, inputValue)"></div>
					<div class="option-name" v-else v-html="highlight(option.name, inputValue)"></div>
					<div v-if="option.type == 'country'" class="option-country">
						Country
					</div>
					<div v-else-if="option.country" class="option-country">
						{{ option.country }}
					</div>
					<!-- <pre>{{ option }}</pre> -->
				</div>

				<span v-if="option.type">
					<img v-if="option.type == 'tour'" class="option-icon" src="@/assets/search/tour-icon.png" alt="tour icon" width="20px"/>
					<img v-else-if="option.type == 'city'" class="option-icon" src="@/assets/search/city-icon.png" alt="tour icon" width="22px"/>
					<img v-else-if="option.type == 'keyword'" class="option-icon" src="@/assets/search-icon.png" alt="tour icon" width="14px"/>
					<img v-else class="option-icon" src="@/assets/search/location-icon.png" alt="location icon" width="20px"/>
				</span>
				<span v-else-if="option.location_type">
					<img v-if="option.location_type == 'city'" class="option-icon" src="@/assets/search/city-icon.png" alt="tour icon" width="22px"/>
					<img v-else-if="option.location_type == 'airport'" class="option-icon" src="@/assets/search/airport-icon.png" alt="tour icon" width="14px"/>
					<img v-else class="option-icon" src="@/assets/search/location-icon.png" alt="location icon" width="20px"/>
				</span>
			</template>

			<!-- <template #option="{ label, icon, code }">
				<img :src="getIcon(icon)">
				<span>{{label}}</span>
			</template> -->
		</v-select>
	</div>
</template>

<script>
	import {  mapActions } from 'vuex'
	import { searchService } from '@/services/searchService.js'

	export default {
    name: 'SearchInput',
	props: ['toggle'],
		data() {
			return {
				OpenIndicator: { // Replaces the arrow with an empty span to hide it
					render: createElement => createElement('span', {class: {'toggle': true}}),
				},
				Deselect: {
					render: createElement => createElement('span', ''),
				},
				icon: "example",
				options: [],
				location: {},

				placeholder: "Search..."
			}
		},
		computed: {
		},
		methods: {
			...mapActions({
				fetchLocationOptions: 'flightsSearch/fetchLocationOptions'
			}),
			handleSelect(option) {
				if(option) {
					if(option.code) {
						option.name = option.name + ' (' + option.code + ')';
					}
					this.$emit("input", option);
				}
			},
			async searchLocations(query, loading) {
				this.inputValue = query; // Set the currently typed in value to variable

				loading(true);
				clearTimeout(this.debounceTimeout);
				this.debounceTimeout = setTimeout(async () => {
					if(query != "") {
						try {
							let allResponse = await searchService.fetchLocationOptions(query, "all");
							// let countriesResponse = await searchService.fetchLocationOptions(query, "countries"); // For some reason 'all' does not include countries.

							// this.options = countriesResponse.data.data.concat(allResponse.data.data);
							this.options = allResponse.data.data;

							this.error = null;
						} catch (error) {
							this.error = error;
						} finally {
							loading(false);
						}
						
					} else {
						loading(false);
					}
				}, 350);
			},
			highlight(text, highlight) {
				let index = text.toLowerCase().indexOf(highlight.toLowerCase());
				if(index >= 0) {
					return text.slice(0, index) + '<span class="highlight">' + text.slice(index, index + highlight.length) + '</span>' + text.slice(index + highlight.length);
				}
				return text;
			},
			focus() { // This is called from outside of the component
				const ele = this.$refs.searchInput.$el.querySelector(`input`);
				ele.focus();
			},
		},
		mounted: function() {
			this.$refs["searchInput"].open = true;
		}
	}
</script>

<style>
	/* Overwrite the v-select styles globally */
	.searchInputWrapper .search-input {
		width: 291px;
		height: 52px;
	}
	.searchInputWrapper .search-input  {
		border: none;
	}
	.searchInputWrapper .search-input .hide {
		display: none;
		visibility: hidden;
	}
	.searchInputWrapper .search-input .info {
		height: 71px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid rgba(60,60,60,.26);
	}
	.searchInputWrapper .search-input .vs__search,
	.searchInputWrapper .search-input .vs__search:focus,
	.searchInputWrapper .search-input .vs__selected {
		padding-left: 20px;
		height: 40px;
	}
	.searchInputWrapper .search-input .vs__dropdown-toggle,
	.searchInputWrapper .search-input.vs--open .vs__dropdown-toggle {
		width: 100%;
		height: 100%;
		border-radius: 50px;
		border-color: rgba(60,60,60,.26);
	}
	.searchInputWrapper .search-input .vs__dropdown-menu {
		margin-top: 2px;
		width: 241px;
		margin-left: 25px;
	}
	.searchInputWrapper .search-input .vs__actions {
		padding-right: 50px;
		background-image: url("../assets/search-icon.png");
		background-repeat: no-repeat;
		background-size: 24px 24px;
		background-position: center right 20px;
	}
	.searchInputWrapper .search-input .vs__search {
		color: black;
		/* opacity: 0.4; */
	}
	.searchInputWrapper .search-input .vs__selected {
		font-weight: bold;
		color: black;
		height: 100%;
		margin-top: 0;
	}
	.searchInputWrapper .search-input .option {
		/* position: relative; */
		/* top: 50%; */
		/* transform: translateY(50%); */
		max-width: 100%;
		
	}
	.searchInputWrapper .search-input .option-icon {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
	.searchInputWrapper .search-input .option-name {
		color: #50555C;
		font-weight: bold;
		font-size: 16px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.searchInputWrapper .search-input .option-name .highlight {
		color: #118AB2;
	}
	.searchInputWrapper .search-input .option-country {
		color: #979797;
		font-size: 13px;
	}
	.searchInputWrapper .search-input .vs__dropdown-option--highlight {
		background: #f9f9f9;
	}
	.searchInputWrapper .search-input .vs__dropdown-menu {
		/* padding-top: 0; */
		top: 100%;
		padding-top: 0px;
		padding-bottom: 0px;
		margin-top: 0;
		border: none;
		box-shadow: initial;
		max-height: 350px;
		/* border-bottom: 1px solid rgba(60,60,60,.26); */
		border-radius: 0;
		border-bottom: none;
	}
	.searchInputWrapper .search-input .vs__dropdown-option {
		padding-bottom: 8px;
		padding-top: 8px;
		padding-right: 40px;
		position: relative;
		min-height: 70px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(60,60,60,.26);
	}
	
</style>